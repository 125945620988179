@if (!isUserViewClicked) {
  <mat-card [formGroup] ="searchUserForm" fxLayout="column" class="content-style">
    <mat-card-content fxLayout="column">
      <div fxLayout="row wrap" fxLayoutAlign="start start">
        <div [formGroup]="searchUserForm" fxLayout="row wrap" fxFlex="100" fxLayoutGap="1.25rem" fxLayoutAlign="start start">
		<div fxFlex="100" fxLayout="row">
			<mat-label id="top-label"
				class="font-style-new">Search Keyword </mat-label>
    </div>
    @if (!advSearch) {
    <mat-form-field fxFlex_lt-sm="100" fxFlex="100" fxLayout_lt-sm="row" appearance="outline"
      style="border-radius:16px !important; background:#F8F7F7;" class="search-field search-user-field">
      <input matInput type="text" id="searchKey" class="pb-1" placeholder="" formControlName="searchKey">
      <div matSuffix fxLayout="row" fxLayoutGap="2px" fxLayoutAlign="end center" class="search-suffix"
        style="width:210px !important;">
    
        <button matSuffix type="button" (click)="searchList()" class="btn btn-sm ux-icon-button search-btn-2" title="Search"
          fxLayout="row" fxLayoutAlign="center center">
          <span>Search</span>
        </button>
      </div>
      <!-- <button matSuffix mat-icon-button aria-label="Clear"
                      (click)="searchUserForm.controls.searchKey.setValue(null)">
                      <mat-icon matSuffix>close</mat-icon>
                    </button> -->
    </mat-form-field>
    }
        </div>
        @if (advSearch) {
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="10px" class="w-100 search-fields-container">
            <div fxLayout="row"  fxLayout.lt-md="column" fxFlex="33" fxFlex.lt-md="100"  fxLayoutAlign="start">
              <div fxFlex="20" fxFlex.lt-md="100" style="align-items:center;">
                <mat-label class="font-style" style="white-space: nowrap;">
                 Cust ID
                </mat-label>
              </div>
              <div fxFlex="80" fxFlex.lt-md="100">
                <mat-form-field appearance="outline" style="border-radius:16px !important;background-color:#F8F7F7;height:67px !important;"  class="search-field w-100">
                  <input matInput type="text" placeholder="" formControlName="customerId">
                </mat-form-field>
              </div>
            </div>
            <div fxLayout="row"  fxLayout.lt-md="column" fxFlex="33" fxFlex.lt-md="100" fxLayoutAlign="start">
              <div fxFlex="23" fxFlex.lt-md="100" style="align-items:center;">
                <mat-label class="font-style"  style="white-space: nowrap;">
                 Email ID
                </mat-label>
              </div>
              <div fxFlex="77" fxFlex.lt-md="100">
                <mat-form-field appearance="outline" style="border-radius:16px !important;background-color:#F8F7F7;height:67px !important;"  class="search-field w-100">
                  <input matInput type="text" placeholder="" formControlName="emailAddress">
                </mat-form-field>
              </div>
            </div>
            <div fxLayout="row"  fxLayout.lt-md="column" fxFlex="33" fxFlex.lt-md="100" fxLayoutAlign="start">
              <div fxFlex="30" fxFlex.lt-md="100" style="align-items:center;">
                <mat-label class="font-style"  style="white-space: nowrap;">
                  Account ID
                </mat-label>
              </div>
              <div fxFlex="70" fxFlex.lt-md="100">
                <mat-form-field appearance="outline" style="border-radius:16px !important;background-color:#F8F7F7;height:67px !important;" class="search-field w-100">
                  <input matInput type="text" placeholder="" formControlName="accountId">
                </mat-form-field>
              </div>
            </div>
          </div>
           <div fxLayout="row" fxLayout_xs="column" fxFlex="100" fxLayoutAlign="space-between start" class="w-100">
            <div fxFlex="48" fxLayout="row" fxLayoutAlign="start center">
              <mat-label fxFlex_lt-sm="100" fxFlex="10" >
                <h3 class="font-style-2" >Roles</h3>
              </mat-label>
              <mat-form-field appearance="outline" fxFlex_lt-sm="100" fxFlex="90" style="border-radius:16px !important;background-color:#F8F7F7;height:67px !important;margin-left:20px !important;"  class="search-field w-100">
                <mat-select [formControl]="searchByRolesFormControl" multiple>
                  <mat-select-trigger>
                    <mat-chip-list>
                      @for (roleChipValue of searchByRolesFormControl.value; track roleChipValue) {
                        <mat-chip [removable]="true"
                          (removed)="onRolesRemoved(roleChipValue)">
                          {{ roleChipValue.label }}
                          <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      }
                    </mat-chip-list>
                  </mat-select-trigger>
                  @for (role of roles; track role) {
                    <mat-option [value]="role">{{role.label}}
                    </mat-option>
                  }
                </mat-select>
                <mat-icon matSuffix><span class="down-arrow-svg inline-block"></span></mat-icon>
              </mat-form-field>
            </div>
            <div fxFlex="48" fxLayout="row" fxLayoutAlign="start center">
              <mat-label fxFlex_lt-sm="100" fxFlex="10">
                <h3 class="font-style-2">Status</h3>
              </mat-label>
              <mat-form-field appearance="outline" fxFlex_lt-sm="100" fxFlex="90"  style="border-radius:16px !important;background-color:#F8F7F7;height:67px !important;margin-left:20px !important;"  class="search-field w-100">
                <mat-select [formControl]="searchByStatusFormControl" multiple>
                  <mat-select-trigger>
                    <mat-chip-list>
                      @for (statusChipValue of searchByStatusFormControl.value; track statusChipValue) {
                        <mat-chip [removable]="true"
                          (removed)="onStatusRemoved(statusChipValue)">
                          {{ statusChipValue.label }}
                          <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      }
                    </mat-chip-list>
                  </mat-select-trigger>
                  @for (st of status; track st) {
                    <mat-option [value]="st"> {{st.label}}
                    </mat-option>
                  }
                </mat-select>
                <mat-icon matSuffix><span class="down-arrow-svg inline-block"></span></mat-icon>
              </mat-form-field>
            </div>
          </div>
        }
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" class="btns-container">
        <button type="button" class="btn btn-sm ux-icon-button-new" fxLayoutGap="2.06rem" fxLayoutGap_md="1rem" fxLayoutGap_lt-md="0.5rem" fxLayout="row" fxLayoutAlign="center center" (click)="advancedSearch()">
          @if (advSearch) {<span class="check-svg"></span>}
          <span>{{advSearch ? "Hide" : "Show"}} Advanced Search</span>
        </button>
        <button type="button" class="btn btn-sm ux-icon-button-new" [ngClass]="{'selected-button' : searchType === 'SHELL'}" fxLayout="row" fxLayoutAlign="center center" (click)="searchList('SHELL')">
          <span>Administer Shell Accounts</span>
        </button>
        <button type="button" class="btn btn-sm ux-icon-button-new" [ngClass]="{'selected-button' : searchType === 'REGISTERED'}" fxLayout="row" fxLayoutAlign="center center" (click)="searchList('REGISTERED')">
          <span>Administer Registered Accounts</span>
        </button>
        @if(advSearch) {
        <button matSuffix type="button" class="btn btn-sm search-btn-3" (click)="searchList(type = '')">
          Search
        </button>
        }
        </div>
      </mat-card-content>
    </mat-card>
    @if (dataSource) {
    <mat-card class="content-style-2">
      <mat-card-content>
      <!-- <mat-table [dataSource]="dataSource" class="live-data-container mat-elevation-z8 mt-2" -->
       <!--  </button> -->
        <!-- @if (dataSource.data.length>0) {
          <button type="button" class="btn btn-sm ux-icon-button" title="Export Data in CSV format"
            (click)="exportToCSV()" fxLayout="row" fxLayoutAlign="start center">-->
            <!-- exporter.exportTable('csv') -->
            <!--<mat-icon data-toggle="tooltip" data-placement="bottom" title="Export Data" class="mr-2">file_download
              </mat-icon><span>Export Data</span>
            </button>
          } -->
        <!-- </div>
      </div> -->
      <uxlib-data-table [dataTableConfig]="dataTableConfiguration" [data]="dataSource"
    (newActionEvent)="getActions($event)" [advSearchOn]="advSearch"></uxlib-data-table>
      <!-- <mat-table [dataSource]="dataSource" class="live-data-container mat-elevation-z8 mt-2"
        [ngClass]="{'mat-table-responsive' : screensize_ltmd}" [hidden]="!dataSource"
        #userSearchTableSort="matSort" matSort>
        <ng-container matColumnDef="index">
          <mat-header-cell *matHeaderCellDef class="sl" mat-sort-header><span>#</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index" class="sl"
          [ngClass]="{'mat-cell-responsive-sm padding-left-md' : screensize_ltsm}"> <span class="d-none"
        [ngClass]="{'mobile-label-display' : screensize_ltsm}">Sl:</span><span [ngClass]="{'ml-2' : screensize_ltsm}">
      {{element.rowIndex}}</span> </mat-cell>
    </ng-container>
    @for (attribute of formAttributeConfig; track attribute; let i = $index) {
      <ng-container matColumnDef="{{attribute.name}}">
        <mat-header-cell *matHeaderCellDef class="pl-1" [class]="attribute.class" mat-sort-header
          [disabled]="attribute.type==='action'">
          <div>
            {{attribute.label}}
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index" class="matCell pl-1" [class]="attribute.class"
          [ngClass]="{'mat-cell-responsive-sm padding-left-md' : screensize_ltsm}">
          @if (attribute.type === 'role') {
            <span fxFlex_lt-sm="100">
              <span [ngClass]="{'d-flex' : screensize_ltsm}" fxFlex_lt-sm="100">
                <span class="d-none" [ngClass]="{'mobile-label-display' : screensize_ltsm}" fxFlex_lt-sm="35">Role</span>
                <span class="font-weight-bold" fxFlex_lt-sm="65">{{element[attribute.name] || '-' }}
                </span>
              </span>
            </span>
          }
          @if (attribute.type === 'phone') {
            <span fxFlex_lt-sm="100">
              <span [ngClass]="{'d-flex' : screensize_ltsm}" fxFlex_lt-sm="100">
                <span class="d-none" [ngClass]="{'mobile-label-display' : screensize_ltsm}" fxFlex_lt-sm="35">Phone</span>
                <span [ngClass]="{'ml-2' : screensize_ltsm}"
                  fxFlex_lt-sm="65">{{!element[attribute.name] ? '-' : element[attribute.name] | phonePipe}}
                </span>
              </span>
            </span>
          }
          @if (attribute.type === 'string') {
            <span fxFlex_lt-sm="100">
              <span [ngClass]="{'d-flex' : screensize_ltsm}" fxFlex_lt-sm="100">
                <span class="d-none" [ngClass]="{'mobile-label-display' : screensize_ltsm}" fxFlex_lt-sm="35">{{attribute.label}}</span>
                <span [ngClass]="{'ml-2' : screensize_ltsm}" fxFlex_lt-sm="65">{{element[attribute.name] || '-'}}
                </span></span>
              </span>
            }
            @if (attribute.type === 'status') {
              <span fxFlex_lt-sm="100">
                <span [ngClass]="{'d-flex' : screensize_ltsm}" fxFlex_lt-sm="100">
                  <span class="d-none" [ngClass]="{'mobile-label-display' : screensize_ltsm}" fxFlex_lt-sm="35">{{attribute.label}}</span>
                  <span [ngClass]="{'ml-2' : screensize_ltsm}" [class]="element.status.value"
                    fxFlex_lt-sm="65">{{( 'user.status.' + element['userStatus'] |  labelTranslate )|| '-'}}
                  </span></span></span>
                }
                @if (attribute.type === 'date') {
                  <span fxFlex_lt-sm="100">
                    <span [ngClass]="{'d-flex' : screensize_ltsm}" fxFlex_lt-sm="100">
                      <span class="d-none" [ngClass]="{'mobile-label-display' : screensize_ltsm}" fxFlex_lt-sm="35">{{attribute.label}}</span>
                      <span fxFlex_lt-sm="65"
                        [ngClass]="{'ml-2' : screensize_ltsm}">{{!element[attribute.name] ? '-' : element[attribute.name] | date: 'short' : uiTimezone }}
                      </span></span></span>
                    }
                    @if (attribute.type === 'action') {
                      <span fxFlex_lt-sm="100">
                        <span [ngClass]="{'d-flex' : screensize_ltsm}" fxFlex_lt-sm="100">
                          <span class="d-none" [ngClass]="{'mobile-label-display' : screensize_ltsm}" fxFlex_lt-sm="35">{{attribute.label}}</span>
                          <span [ngClass]="{'ml-2' : screensize_ltsm}" fxFlex_lt-sm="65"><button data-toggle="tooltip" title="View User Details"
                          class="btn btn-link p-0" (click)="userDetailViewEnabled(element)">User View</button>
                        </span></span></span>
                      }
                    </mat-cell>
                  </ng-container>
                }
                <tr class="mat-row d-flex" *matNoDataRow>
                  <td class="mat-cell d-flex w-100 p-3 font-weight-bold" fxLayoutAlign="center"
                    [attr.colspan]="displayedColumns.length">
                    No data available.
                  </td>
                </tr>
                <mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{'d-none' : screensize_ltsm}"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'responsive-mat-row' : screensize_ltmd}"
                [ngClass]="{'responsive-mat-row-small' : screensize_ltsm}"></mat-row>
              </mat-table> -->
              <!-- <mat-paginator #paginator="matPaginator"  *ngIf="dataSource" [length]="dataSource.data?.length"
              [pageSize]="dataSource.filteredData.length > 10 ? '10' : dataSource.filteredData.length"
              [pageSizeOptions]="[10,20,30,40,50]" showFirstLastButtons aria-label="Show users"
              [ngClass]="dataSource !== null && dataSource.filteredData.length > 0  ? 'visible' : 'hidden'">
            </mat-paginator>  -->
            <!-- @if (dataSource) {
              <mat-paginator #paginator="matPaginator" [pageSizeOptions]="[10, 20, 30, 40, 50]" showFirstLastButtons
                [pageSize]="10" aria-label="Select page">
              </mat-paginator>
            } -->
          </mat-card-content>
        </mat-card>
      }
}
