import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit, Injector } from '@angular/core';
import { FloatingMenuItems, MenuItem, FloatingTopMenuComponent, AbstractComponent } from '@londonhydro/ux-lib';
import { CsrDataService } from 'src/app/backend/csr/dataservice/csr-data.service';

@Component({
  selector: 'app-navigation-banner',
  templateUrl: './navigation-banner.component.html',
  styleUrl: './navigation-banner.component.scss'
})
export class NavigationBannerComponent extends AbstractComponent implements OnInit, OnDestroy, AfterViewInit {
  floatingMenuItems: FloatingMenuItems;
  dynamicBannerClass: string;
  @ViewChild('bannerContainer') bannerContainer: ElementRef;
  private intersectionObserver: IntersectionObserver;

  navigationMenuItems: MenuItem [] = [];
  stickyMenuItems: MenuItem [] = [];
  isNavPanelSticky = false;

  @Output()
  onNavPanelSticky = new EventEmitter<boolean>();




  constructor(injector: Injector, private csrDataService: CsrDataService) {
    super(injector);
  }

  ngOnInit(): void {
    this.populateFloatingMenu();
    this.populateNavigationPanel();
  }

  populateFloatingMenu(): void {
    this.floatingMenuItems = new FloatingMenuItems(
      new MenuItem(1, 'myaccount', 'Menu', 'myaccount', 'floating-menu-myaccount',
        'floating-menu-user-icon-svg', '', '', '', true, false, 'arrow_drop_down'), null
    );

    this.floatingMenuItems.mainMenuItems.addSubMenu(MenuItem.create(0, 'openLoginEmail', 'Change Login Email', null, 'floating-menu-change-login-email',
      '', '', '', '', true, false, null, null, null, 'contextFunction'));
    if (this.loginUser.standardLogin) {
      this.floatingMenuItems.mainMenuItems.addSubMenu(MenuItem.create(1, 'openChangePwd', 'Change Account Password', null, 'floating-menu-change-password',
        '', '', '', '', true, false, null, null, null, 'contextFunction'));
      this.floatingMenuItems.mainMenuItems.addSubMenu(MenuItem.create(2, 'openEnableMFA', 'Account Security & MFA', null, 'floating-menu-mfa',
        '', '', '', '', true, false, null, null, null, 'contextFunction'));
    }
    this.floatingMenuItems.mainMenuItems.addSubMenu(MenuItem.create(3, 'openChangeEmailOptIn', 'Change Email Opt-In', null, 'floating-menu-emailoptin',
      '', '', '', '', true, false, null, null, null, 'contextFunction'));
    this.floatingMenuItems.mainMenuItems.addSubMenu(MenuItem.create(4, 'openCloseOnlineAccount', 'Close Online Account', null, 'floating-menu-clone-online-account',
      '', '', '', '', true, false, null, null, null, 'contextFunction'));
    this.floatingMenuItems.mainMenuItems.addSubMenu(MenuItem.create(5, 'logout', 'Logout', '/logout', 'floating-menu-logout',
      '', '', '', '', true, false));
  }

  ngAfterViewInit(): void {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0
    };

    this.intersectionObserver = new IntersectionObserver((entries: any) => {
      const navBar = document.querySelector('.navigation-panel-parent');
      entries.forEach((entry: any) => {
        if (!entry.isIntersecting) {
          navBar?.classList.add('sticky');
          this.onNavPanelSticky.emit(true);
        } else {
          navBar?.classList.remove('sticky');
          this.onNavPanelSticky.emit(false);
        }
      });
    }, options);
    this.intersectionObserver.observe(this.bannerContainer.nativeElement);
  }


  populateNavigationPanel(): void{
    this.csrDataService.getMenuList().subscribe((mList: MenuItem[]) => {
    const menuMap: Map<number, MenuItem[]> = new Map();
    mList.forEach((rt: MenuItem, index: number) => {
      if (rt.parentMenuId){
      if (menuMap.has(rt.parentMenuId)){
        const menu: MenuItem[] = menuMap.get(rt.parentMenuId);
        menu.push(rt);
        menuMap.set(rt.parentMenuId, menu);
      }else{
        const menu: MenuItem[] = [];
        menu.push(rt)
        menuMap.set(rt.parentMenuId, menu);
      }
      }
    });

    mList.forEach((rt: MenuItem) => {
      if (!rt.parentMenuId){
        const menu = MenuItem.create(rt.id, rt.name, rt.label, rt.action,
            null, null, null, null, rt.role, true,
             null, null, (rt.subLabel || null), (rt.activeSubLabel || null)
          );
        if (menuMap.has(rt.id)) {
          menuMap.get(rt.id).forEach((rtSub: MenuItem) => {
            menu.addSubMenu(MenuItem.create(rtSub.id, rtSub.name, rtSub.label, rtSub.action,
            null, null, null, null, rtSub.role, true,
             null, null, (rtSub.subLabel || null), (rtSub.activeSubLabel || null)
          ));
          });
        }
        this.navigationMenuItems.push(menu)
      }
    });

    this.stickyMenuItems.push(
      MenuItem.create(0, null, 'My Account', 'myAccount',
            'my-account-menu-item', null, null, null, null, false,
             true, null, null, null
          )
    );
    });
  }


  ngOnDestroy(): void {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  }


}
